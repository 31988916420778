.myDiv {
  box-sizing: border-box;
}

.myDiv > :first-child {
  margin-top: 0;
}

.myDiv > :last-child {
  margin-bottom: -30px;
}

@media (max-width: 1200px) {
  .myDiv > :last-child {
    margin-bottom: -27px;
  } 
}
