.voice-wave-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	background: #f5f5f5;
}

.waves {
	display: flex;
	gap: 1px;
}

.wave {
	width: 3px;
	height: 12px;
	background: rgb(254, 106, 87);
	border-radius: 1px;
	transform: scaleY(0.5);
	animation: wave-animation 1.2s infinite;
	opacity: 0.7;
}

.wave.active {
	animation: wave-animation 1s infinite;
}

@keyframes wave-animation {
	0%,
	100% {
		transform: scaleY(0.5);
	}
	50% {
		transform: scaleY(1.5);
	}
}
