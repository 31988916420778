.hold {
	position: absolute;
	width: 100%;
	height: 100%;
	clip: rect(0px, 132px, 132px, 66px);
	border-radius: 100%;
}

.hold-light {
	background-color: #f7f6f4;
}

.hold-dark {
	background-color: #465359;
}


.fill {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	clip: rect(0px, 66px, 132px, 0px);
	background-color: #fe6a57;
}

.left .fill {
	z-index: 1;
	animation: left 15s linear both;
}

@keyframes left {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(180deg);
	}
}

.right {
	z-index: 3;
	transform: rotate(180deg);
}

.right .fill {
	z-index: 3;
	animation: right 15s 15s linear both;
}

@keyframes right {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(180deg);
	}
}
