.markdown-content > :first-child {
  margin-top: 9px;
}

.markdown-content > :last-child {
  margin-bottom: 9px;
}

.singleMessageWrapper {
  width: 90%;
  margin: 0 auto;
	display: flex;
	gap: 15px;
	margin-block: 10px;
	box-sizing: border-box;
	padding-left: 10px;
}

.actionMessageWrapper {
	margin-block: 10px;
	box-sizing: border-box;
	padding-left: 10px;
}

@media (max-width: 600px) {
  .singleMessageWrapper {
    justify-content: space-between;
  }
}

@media (min-width: 1600px) {
  .singleMessageWrapper, .actionMessageWrapper {
    padding-left: 10px;
  }
}